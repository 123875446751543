import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`With all the designed flow in mountain biking these days, it looks like we
became lazy bikers. Coming to a destination, we expect a perfectly composed
menu of great experiences. Smooth, save, and sound.`}</p>
    <br />
    <p>{`Ticking off trail after trail, like robots. Experiences that almost become
indistinguishable. How many trails did we ride already? Ten, thousand? It
doesn't matter and fades into the insignificance of equality. Like someone
put a soft focus over a picture. Forms, light, contrast and colors are
blurred until a gray canvas is left.`}</p>
    <br />
    <p>{`You can be sure, Mallorca is different. Mallorcas trails are wild and
untamed. No signs, no berms, no flow. You will climb steep, loose roads,
carry and push your bike, up and down, climb over fences, backtrack of
dead ends, get stuck in an ocean of rocks, curse, sweat, bleed. Breath.`}</p>
    <br />
    <p>{`And you will smile. Maybe not right away, but as your scratches heal,
your smile will get bigger. You accepted the challenge and you made it.
If you want that, work hard, experience the island and biking in a different
way, and if you love rocks, you should go to Mallorca.`}</p>
    <TrailguidePlugin content="lat=39.7061&lng=2.7747&coverage=30" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=10,39.7061,2.7747"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Ses Basses a La Trapa`}</h4>
    <p>{`Mallorcas landscape is rough and beautiful. A challenging trail follows the
steep coast in the south west of the island.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2586"
      }}>{`trailguide.net/2586`}</a>{`.`}</p>
    <Image src="destinations/mallorca_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Galatzo Al Porxo`}</h4>
    <p>{`And here we go. Rocks everywhere. This is one of the easier trails. Less
blocked and faster. But take care not to clip your pedals!
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2588"
      }}>{`trailguide.net/2588`}</a>{`.`}</p>
    <Image src="destinations/mallorca_11.jpg" mdxType="Image" />
    <br />
    <h4>{`Pas d'Honor`}</h4>
    <p>{`You have to work your bike a lot over the many rocks and through the tight
turns in the woods. Then you can get some of the rare Majorcan flow.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2575"
      }}>{`trailguide.net/2575`}</a>{`.`}</p>
    <Image src="destinations/mallorca_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Pas d'Honor`}</h4>
    <p>{`In the heart of the Tramuntana mountain region, there is a lot to discover.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2575"
      }}>{`trailguide.net/2575`}</a></p>
    <Image src="destinations/mallorca_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Tuneles Almadra`}</h4>
    <p>{`We are surfing on the loose rocks, straight into the tunnels of Almadra.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2587"
      }}>{`trailguide.net/2587`}</a>{`.`}</p>
    <Image src="destinations/mallorca_14.jpg" mdxType="Image" />
    <br />
    <h4>{`Tuneles Almadra`}</h4>
    <p>{`Lights on and tuck.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2587"
      }}>{`trailguide.net/2587`}</a>{`.`}</p>
    <Image src="destinations/mallorca_16.jpg" mdxType="Image" />
    <br />
    <h4>{`Tuneles Almadra`}</h4>
    <p>{`In between the tunnels we try to find the grip on the loose and steep
trail of the Almadra valley.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2587"
      }}>{`trailguide.net/2587`}</a>{`.`}</p>
    <Image src="destinations/mallorca_15.jpg" mdxType="Image" />
    <br />
    <h4>{`Pinatons`}</h4>
    <p>{`After a short climb, the Pinatons trail turns out to be even more
difficult. Rocky switchbacks challenge our tired bodies.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2584"
      }}>{`trailguide.net/2584`}</a>{`.`}</p>
    <Image src="destinations/mallorca_17.jpg" mdxType="Image" />
    <br />
    <h4>{`Tapas`}</h4>
    <p>{`Rest. Spain treats us well with Tapas and very good beer. On Ramiros face
you can see the traces Majorcan rocks left.`}</p>
    <Image src="destinations/mallorca_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Port de Sollér`}</h4>
    <p>{`Port de Sollér is a beautiful village at a quiet bay in the west of the
island. Overcrowded in the summer, there are only locals during the winter.`}</p>
    <Image src="destinations/mallorca_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Es Cocons`}</h4>
    <p>{`Beside the gray rocks, the nature is very green. Jungle feeling on this
trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2576"
      }}>{`trailguide.net/2576`}</a>{`.`}</p>
    <Image src="destinations/mallorca_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Coma De S'arc`}</h4>
    <p>{`We always kept a safe distance to the party beaches west of Palma de Mallorca.
A visit of the old part of Palma is absolutely worth it.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2585"
      }}>{`trailguide.net/2585`}</a></p>
    <Image src="destinations/mallorca_18.jpg" mdxType="Image" />
    <br />
    <h4>{`Lighthouse`}</h4>
    <p>{`Mallorca is special. You should be able to ride very good.
Like technical, rocky trails, and be fit enough to go the extra mile.
Winter is a perfect time for a visit. In the summer the island is crowded
by beach tourists, in the spring by road bikers. Beside flying there,
you can also take the ferry from the Spanish mainland.`}</p>
    <Image src="destinations/mallorca_6.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      